import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useSession } from '@/providers/session/SessionProvider'
import { ROOT } from '@/routes'

import './RecoverPassword.scss'

const NoMatch = () => {
  const { replace } = useHistory()
  const { pathname, search = '' } = useLocation()
  const { setUiTab } = useSession()

  useEffect(() => {
    setUiTab(pathname + search)

    replace(ROOT)
  }, [replace, pathname, search, setUiTab])

  return null
}

export default NoMatch
